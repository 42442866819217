import { ROUTES } from '@/App/routes/routes';
import { useUserProfile } from '@/hooks/Authentication/useUserProfile';
import { useMissions } from '@/hooks/Mission/useMissions';
import { useStore } from '@/store';
import {
  isFeatureFlagInternalWorkshopEnabled,
  isFeatureFlagLpsAppEnabled
} from '@/utils/FeatureFlags';
import { TourUtils } from '@/utils/TourUtils';
import { MissionType } from '@badgermoleV2/api';
import { isLoggedIn } from '@badgermoleV2/client';
import { AccountCircle, LocalShipping, Traffic } from '@mui/icons-material';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import HandymanIcon from '@mui/icons-material/Handyman';
import HomeIcon from '@mui/icons-material/Home';
import KeyIcon from '@mui/icons-material/Key';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import {
  Button,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch
} from '@mui/material';
import MoneyIcon from '@mui/icons-material/Money';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { DrawerSelectionItem } from './DrawerSelectionItem';
import { LanguageSelection } from './LanguageSelection';

type CustomDrawerProps = {
  readonly open: boolean;
  readonly toggle: () => void;
};

export const CustomDrawer = ({ open, toggle }: CustomDrawerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const APP_VERSION = import.meta.env.VITE_VERSION!;

  const { user } = useUserProfile();
  const isUserLoggedIn = isLoggedIn();

  const [OCXXSwitchAllowedByUserGroup, OCXXSwitchSettingAllowed, OCXXEnabled, setOCXXEnabled] =
    useStore((s) => [
      s.OCXXSwitchAllowedByUserGroup,
      s.OCXXSwitchSettingAllowed,
      s.OCXXEnabled,
      s.setOCXXEnabled
    ]);

  const {
    isLoadingOpenTours,
    isLoadingMissions,
    customMission,
    openTours,
    pickupMission,
    relocateMission,
    serviceMission
  } = useMissions({
    isDrawerOpen: open,
    disableCustom: !isUserLoggedIn,
    disableOpenTours: !isUserLoggedIn,
    disablePickup: !isUserLoggedIn || import.meta.env.VITE_DISABLE_PICKUP === 'true',
    disableRelocate: !isUserLoggedIn,
    disableService: !isUserLoggedIn
  });

  const loading = isLoadingMissions || isLoadingOpenTours;

  const navigateTo = (path: string) => {
    toggle();
    navigate(path);
  };

  if (!isUserLoggedIn) {
    return (
      <Drawer data-testid="drawer" anchor={'left'} open={open} onClose={toggle}>
        <List data-testid="drawerList" sx={{ height: '100%' }}>
          <LanguageSelection />
          <Divider />
          <ListItem sx={{ position: 'absolute', bottom: 0 }}>
            <ListItemText primary={APP_VERSION} secondary={'App Version'} />
          </ListItem>
        </List>
      </Drawer>
    );
  }

  return (
    <Drawer data-testid="drawer" anchor="left" open={open} onClose={toggle}>
      <List data-testid="drawerList" sx={{ height: '100%' }}>
        <LanguageSelection />
        <ListItem key={'username'}>
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText primary={user?.username} />
        </ListItem>

        {OCXXSwitchAllowedByUserGroup && OCXXSwitchSettingAllowed && (
          <ListItem>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    data-testid="debugSwitch"
                    color={'primary'}
                    checked={OCXXEnabled}
                    onChange={(e) => setOCXXEnabled(e.target.checked)}
                    name="Debug"
                  />
                }
                label={'OCXX Mode'}
              />
            </FormGroup>
          </ListItem>
        )}
        <Divider />
        {(isLoadingMissions || isLoadingOpenTours) && <LinearProgress />}
        <Divider />
        <DrawerSelectionItem
          dataTestId={'drawerHomeItem'}
          title={'Home'}
          onClick={() => {
            navigateTo(ROUTES.OVERVIEW);
          }}
          IconComponent={<HomeIcon />}
        />
        <DrawerSelectionItem
          title={t('umparken:title')}
          onClick={() => {
            navigateTo(ROUTES.UMPARKEN);
          }}
          IconComponent={<LocalParkingIcon />}
        />
        <DrawerSelectionItem
          title={t('report-vehicle:title')}
          onClick={() => {
            navigateTo(ROUTES.REPORTS);
          }}
          IconComponent={<NoteAddIcon />}
        />
        <DrawerSelectionItem
          dataTestId={'pickupTourDrawerItem'}
          title={t('pickup-tour:title')}
          disabled={
            TourUtils.tourFeatureDisabled(pickupMission, openTours, MissionType.Pickup) || loading
          }
          running={TourUtils.tourRunning(openTours, MissionType.Pickup)}
          onClick={() => {
            navigateTo(
              `${ROUTES.PICKUP.ROOT}${
                TourUtils.tourRunning(openTours, MissionType.Pickup)
                  ? ROUTES.TOUR.RUN
                  : ROUTES.TOUR.PREPARE
              }`
            );
          }}
          IconComponent={<LocalShipping />}
        />
        <DrawerSelectionItem
          dataTestId={'serviceTourDrawerItem'}
          title={t('service-tour:title')}
          disabled={
            TourUtils.tourFeatureDisabled(serviceMission, openTours, MissionType.Swap) || loading
          }
          running={TourUtils.tourRunning(openTours, MissionType.Swap)}
          onClick={() => {
            navigateTo(
              `${ROUTES.SERVICE.ROOT}${
                TourUtils.tourRunning(openTours, MissionType.Swap)
                  ? ROUTES.TOUR.RUN
                  : ROUTES.TOUR.PREPARE
              }`
            );
          }}
          IconComponent={<ElectricalServicesIcon />}
        />
        <DrawerSelectionItem
          title={t('custom-tour:title')}
          disabled={
            TourUtils.tourFeatureDisabled(customMission, openTours, MissionType.Custom) || loading
          }
          running={TourUtils.tourRunning(openTours, MissionType.Custom)}
          onClick={() => {
            navigateTo(`${ROUTES.SERVICE.ROOT}${ROUTES.TOUR.RUN}`);
          }}
          IconComponent={<KeyIcon />}
        />
        <DrawerSelectionItem
          dataTestId={'relocateTourDrawerItem'}
          title={t('relocate:title')}
          disabled={
            TourUtils.tourFeatureDisabled(relocateMission, openTours, MissionType.Relocate) ||
            loading
          }
          running={TourUtils.tourRunning(openTours, MissionType.Relocate)}
          onClick={() => {
            navigateTo(ROUTES.RELOCATE + ROUTES.TOUR.RUN);
          }}
          IconComponent={<CarRepairIcon />}
        />
        {isFeatureFlagInternalWorkshopEnabled && (
          <>
            <DrawerSelectionItem
              title={t('workshop:onstreet.title')}
              onClick={() => {
                navigateTo(ROUTES.WORKSHOP.ONSTREET);
              }}
              IconComponent={<Traffic />}
            />
            <DrawerSelectionItem
              title={t('workshop:title')}
              onClick={() => {
                navigateTo(ROUTES.WORKSHOP.ROOT);
              }}
              IconComponent={<HandymanIcon />}
            />
          </>
        )}
        {isFeatureFlagLpsAppEnabled && (
          <DrawerSelectionItem
            title={t('licence-plate-swap:title')}
            dataTestId={'selectLicencePlateSwap'}
            onClick={() => {
              navigateTo(ROUTES.LICENCE_PLATE_SWAP);
            }}
            IconComponent={<MoneyIcon />}
          />
        )}
        <Button
          type="button"
          color="primary"
          variant="contained"
          fullWidth
          data-testid="logout"
          onClick={() => {
            toggle();
            navigate(ROUTES.LOGOUT_REDIRECT);
          }}
        >
          {t('common:auth.logout')}
        </Button>
        <Divider />
        <ListItem>
          <ListItemText primary={APP_VERSION} secondary={'App Version'} />
        </ListItem>
      </List>
    </Drawer>
  );
};
