import type { VehilceWithDistance } from '@/utils/MapUtils';
import type { Vehicle } from '@badgermoleV2/api';
import { Box, List, ListItemButton, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

type VehicleSelectionProps = {
  readonly vehicleList: Vehicle[];
  readonly setVehicle: React.Dispatch<React.SetStateAction<Vehicle | undefined>>;
  readonly vehicleWithDistanceList?: VehilceWithDistance[];
};

export const VehicleSelection = ({
  vehicleList,
  setVehicle,
  vehicleWithDistanceList
}: VehicleSelectionProps) => {
  const { t } = useTranslation();

  if (vehicleList.length === 0)
    return (
      <Box sx={{ margin: '0.5rem' }} textAlign={'center'} data-testid="searchResultList">
        {t('report-vehicle:search.noneFound')}
      </Box>
    );

  if (vehicleList.length > 100)
    return <Box data-testid="searchResultList">{t('report-vehicle:search.specifySearch')}</Box>;

  return (
    <Box
      sx={{
        maxHeight: '300px',
        minWidth: 'fit-content',
        overflowY: 'scroll'
      }}
    >
      <List data-testid="searchResultList">
        {vehicleList.map((vehicle) => (
          <ListItemButton divider key={vehicle.vehicleId} onClick={() => setVehicle(vehicle)}>
            <ListItemText
              primary={vehicle.licencePlate}
              secondary={`${vehicleWithDistanceList ? `(${vehicleWithDistanceList.find((vwd) => vwd.vehicle.vin === vehicle.vin)?.distanceToUser.toPrecision(3)} km ${t('common:geolocation.distance')})` : ''}`}
            />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};
