import { useTitle } from '@/hooks/Utility/useTitle';
import type { ExtendedBase64Image } from '@/utils/ReportUtils';
import type { Vehicle } from '@badgermoleV2/api';
import { Search, Send } from '@mui/icons-material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import type { SnackbarOrigin } from '@mui/material';
import {
  Alert,
  Box,
  Button,
  Fab,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
  Typography
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { VehicleSelection } from '../VehicleReport/VehicleSelection';
import { ImagePreview } from '../VehicleReport/ImagePreview';
import {
  uselicencePlateSwap,
  type ReplacementData
} from '@/hooks/LicencePlateSwap/useLicencePlateSwap';
import { Scanner, type IDetectedBarcode } from '@yudiel/react-qr-scanner';
import { grey } from '@mui/material/colors';
import Webcam from 'react-webcam';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import CloseIcon from '@mui/icons-material/Close';

const SnackBarPosition: SnackbarOrigin = { vertical: 'top', horizontal: 'center' };

export const LicencePlateSwapScreen = () => {
  useTitle({ title: 'licence-plate-swap:title' });
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const [licencePlate, setLicencePlate] = useState('');
  const [oldLpImage, setOldLpImage] = useState<ExtendedBase64Image>();
  const [newLpImage, setNewLpImage] = useState<ExtendedBase64Image>();
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [isVehicleUnknown, setIsVehicleUnknown] = useState(false);
  const [replacementData, setReplacementData] = useState<ReplacementData>();
  const [vinCheck, setVinCheck] = useState<string>('');
  const [barcode, setBarcode] = useState<string>('');
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
  const [isCodeTextInputOpen, setIsCodeTextInputOpen] = useState<boolean>(false);
  const [typedCode, setTypedCode] = useState<string>('');
  const [isCameraOn, setIsCameraOn] = useState<boolean>(false);
  const [takingOldLp, setTakingOldLp] = useState<boolean>(false);
  const webcamRef = useRef<Webcam>(null);
  const [isSelfie, setIsSelfie] = useState<boolean>(false);

  const {
    vehicleListWithDistance,
    disableSend,
    isSending,
    updateLp,
    totalImageSizeReached,
    isLoadingVehicle,
    compressFileList,
    processingImages,
    replacementLpList,
    convertToExtendedBase64Image
  } = uselicencePlateSwap(licencePlate, oldLpImage, newLpImage, vehicle);

  const resetLpsData = () => {
    setLicencePlate('');
    setOldLpImage(undefined);
    setNewLpImage(undefined);
    setVehicle(undefined);
    setIsVehicleUnknown(false);
    setVinCheck('');
    setBarcode('');
  };
  const isVinCorrect =
    vinCheck.length === 4 &&
    replacementLpList.length > 0 &&
    vinCheck === replacementLpList[0].restrictedToVin.slice(-4) &&
    replacementData !== undefined &&
    replacementData.vin.length > 0 &&
    vinCheck === replacementData?.vin.slice(-4);

  const handleScan = (bc: IDetectedBarcode[]) => {
    setBarcode(bc[0].rawValue);
    setIsScannerOpen(false);
  };

  const isBigLp: boolean =
    vehicle === undefined ? false : vehicle.licencePlate.split('-', 1)[0].length !== 3;

  const isSendDisabled: boolean =
    !isVinCorrect ||
    disableSend ||
    (!vehicle && !isVehicleUnknown) ||
    (isBigLp && barcode.length !== 3 && typedCode.length !== 3);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      const i = convertToExtendedBase64Image(imageSrc);
      takingOldLp ? setOldLpImage(i) : setNewLpImage(i);
    }
    setIsCameraOn(false);
  }, [webcamRef, takingOldLp]);

  useEffect(() => {
    if (vehicleListWithDistance.length === 1) setVehicle(vehicleListWithDistance[0].vehicle);
    if (vehicle?.vin && replacementLpList.length > 0)
      setReplacementData({
        vin: vehicle.vin,
        newLpId: replacementLpList[0].id,
        deregristrationCode:
          barcode.length === 3 ? barcode : typedCode.length === 3 ? typedCode : undefined,
        images: [oldLpImage?.image, newLpImage?.image].filter((i) => i !== undefined)
      });
  }, [
    vehicleListWithDistance,
    vehicle,
    replacementLpList,
    barcode,
    typedCode,
    oldLpImage,
    newLpImage
  ]);

  return (
    <>
      {isCameraOn ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: 1,
            gap: 1,
            alignItems: 'center',
            overflow: 'clip'
          }}
        >
          <Webcam
            height={'400px'}
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{ facingMode: isSelfie ? 'user' : { exact: 'environment' } }}
          />
          <Typography sx={{ marginRight: '4rem' }}>
            {t('licence-plate-swap:cameraSwitchHint')}
            {<CameraswitchIcon fontSize="small" />} -button.
          </Typography>
          <Fab
            sx={{ position: 'fixed', bottom: '9rem', right: '1rem' }}
            data-testid="take-photo"
            color="secondary"
            onClick={capture}
          >
            <AddAPhotoIcon />
          </Fab>
          <Fab
            sx={{ position: 'fixed', bottom: '5rem', right: '1rem' }}
            data-testid="switch-camera"
            color="info"
            onClick={() => (isSelfie ? setIsSelfie(false) : setIsSelfie(true))}
          >
            <CameraswitchIcon />
          </Fab>
          <Fab
            sx={{ position: 'fixed', bottom: '1rem', right: '1rem' }}
            data-testid="close-camera"
            color="primary"
            onClick={() => setIsCameraOn(false)}
          >
            <CloseIcon />
          </Fab>
        </Box>
      ) : isScannerOpen ? (
        <Box
          sx={{
            margin: 2,
            maxWidth: '1000px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Scanner onScan={handleScan} formats={['data_matrix']} />
          <Button
            sx={{ width: 'fit-content' }}
            variant="contained"
            data-testid="close-scanner"
            onClick={() => setIsScannerOpen(false)}
          >
            {t('licence-plate-swap:closeQrScan')}
          </Button>
        </Box>
      ) : (
        <>
          {isSending ? (
            <CircularProgress
              sx={{ position: 'fixed', bottom: '1rem', right: '1rem' }}
              color="primary"
              size={40}
            />
          ) : (
            <Fab
              disabled={isSendDisabled}
              sx={{ position: 'fixed', bottom: '1rem', right: '1rem' }}
              color="secondary"
              data-testid="submit"
              onClick={async () => {
                updateLp(replacementData!);
                resetLpsData();
              }}
            >
              <Send />
            </Fab>
          )}
          <Snackbar anchorOrigin={SnackBarPosition} open={isSending}>
            <Alert severity="info"> {t('licence-plate-swap:updateingLp')}</Alert>
          </Snackbar>
          {!(searchParams.get('disableSearch') === 'true') && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, margin: '1rem' }}>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                }}
              >
                <TextField
                  fullWidth
                  size={'small'}
                  label={t('licence-plate-swap:searchOldLp')}
                  placeholder={t('licence-plate-swap:searchOldLp')}
                  value={licencePlate}
                  disabled={isVehicleUnknown}
                  onChange={(e) => {
                    setLicencePlate(e.target.value);
                    setVehicle(undefined);
                    setVinCheck('');
                    setBarcode('');
                    setTypedCode('');
                    setIsScannerOpen(false);
                    setIsCodeTextInputOpen(false);
                    setOldLpImage(undefined);
                    setNewLpImage(undefined);
                  }}
                  slotProps={{
                    htmlInput: { 'data-testid': 'search' },
                    input: {
                      endAdornment: (
                        <IconButton
                          data-testid="searchButton"
                          disabled={isVehicleUnknown || licencePlate.length < 3}
                          type="submit"
                        >
                          <Search />
                        </IconButton>
                      )
                    }
                  }}
                />
              </form>
              {!vehicle && licencePlate.length >= 3 && (
                <VehicleSelection
                  vehicleList={vehicleListWithDistance.map((vwd) => vwd.vehicle)}
                  setVehicle={setVehicle}
                  vehicleWithDistanceList={vehicleListWithDistance}
                />
              )}

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  label={t('licence-plate-swap:vinCheck')}
                  placeholder={t('licence-plate-swap:vinCheck')}
                  value={vinCheck}
                  onChange={(e) => {
                    setVinCheck(e.target.value);
                  }}
                  data-testid="vinCheck"
                  slotProps={{
                    input: {
                      endAdornment: isVinCorrect ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <CancelIcon color="error" />
                      )
                    }
                  }}
                />
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'space-evenly',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                marginLeft: 2,
                marginRight: 2,
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <Typography fontWeight="bold">{t('licence-plate-swap:old')}</Typography>
                {!isVehicleUnknown &&
                  (isLoadingVehicle ? (
                    <LinearProgress />
                  ) : !vehicle ? (
                    <Typography
                      sx={{ margin: '0.5rem' }}
                      textAlign={'center'}
                      data-testid="vehicleSearchResult"
                    >
                      {t('licence-plate-swap:noVehicleSelected')}
                    </Typography>
                  ) : (
                    <Typography
                      textAlign={'center'}
                      data-testid="OldLicencePlate"
                      fontWeight="bold"
                      fontSize={30}
                      sx={{
                        padding: '0.5rem',
                        backgroundColor: grey[200],
                        border: '1px solid black',
                        borderRadius: 2
                      }}
                    >
                      {vehicle.licencePlate}
                    </Typography>
                  ))}
                {vehicle ? (
                  oldLpImage ? (
                    <ImagePreview
                      images={oldLpImage ? [oldLpImage] : []}
                      onSingleImageRemove={(url: string) => {
                        oldLpImage?.identifier === url
                          ? setOldLpImage(undefined)
                          : newLpImage?.identifier === url
                            ? setNewLpImage(undefined)
                            : null;
                      }}
                    />
                  ) : (
                    <Button
                      disabled={processingImages || oldLpImage !== undefined}
                      color="secondary"
                      data-testid="addOldLpImageButton"
                      variant="outlined"
                      component="label"
                      sx={{ display: 'flex', flexDirection: 'column' }}
                      onClick={() => {
                        setTakingOldLp(true);
                        setIsCameraOn(true);
                      }}
                    >
                      <AddAPhotoIcon />
                      {/* This could be implemented as well, for people who use a pc and rather want to select a file. TBD
                  <input
                    hidden
                    accept="image/png,image/jpeg"
                    id="choose-files"
                    name="image"
                    type="file"
                    onChange={async (e) => {
                      const image = await compressFileList(e.target.files![0]);
                      if (image) {
                        setOldLpImage(image[0]);
                        return;
                      }
                    }}
                  /> */}
                    </Button>
                  )
                ) : (
                  ''
                )}
                {totalImageSizeReached && (
                  <Box
                    data-testid="imagesLimit"
                    color={'red'}
                    fontWeight={'bold'}
                    sx={{ marginTop: '0.5rem' }}
                  >
                    {t('licence-plate-swap:imageSizeExceeded')}
                  </Box>
                )}
              </Box>
              <KeyboardDoubleArrowRightIcon color="primary" sx={{ fontSize: 60 }} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <Typography data-testid="NewLicencePlate" fontWeight="bold">
                  {t('licence-plate-swap:new')}
                </Typography>
                {replacementLpList?.length > 0 ? (
                  <Typography
                    textAlign={'center'}
                    data-testid="NewLicencePlate"
                    fontWeight="bold"
                    fontSize={30}
                    sx={{
                      padding: '0.5rem',
                      backgroundColor: grey[200],
                      border: '1px solid black',
                      borderRadius: 2
                    }}
                  >
                    {replacementLpList[0].title}
                  </Typography>
                ) : (
                  <Typography
                    textAlign={'center'}
                    data-testid="NoNewLicencePlate"
                    sx={{ margin: '0.5rem' }}
                  >
                    {t('licence-plate-swap:newLpNotFound')}
                  </Typography>
                )}
                {replacementLpList?.length > 0 ? (
                  newLpImage ? (
                    <ImagePreview
                      images={newLpImage ? [newLpImage] : []}
                      onSingleImageRemove={(url: string) => {
                        oldLpImage?.identifier === url
                          ? setOldLpImage(undefined)
                          : newLpImage?.identifier === url
                            ? setNewLpImage(undefined)
                            : null;
                      }}
                    />
                  ) : (
                    <Button
                      disabled={processingImages || newLpImage !== undefined}
                      color="secondary"
                      data-testid="addNewLpImageButton"
                      variant="outlined"
                      component="label"
                      onClick={() => {
                        setTakingOldLp(false);
                        setIsCameraOn(true);
                      }}
                    >
                      <AddAPhotoIcon />
                      {/* <input
                    hidden
                    accept="image/png,image/jpeg"
                    id="choose-files"
                    name="image"
                    type="file"
                    onChange={async (e) => {
                      const image = await compressFileList(e.target.files![0]);
                      if (image) {
                        setNewLpImage(image[0]);
                        return;
                      }
                    }}
                  /> */}
                    </Button>
                  )
                ) : (
                  ''
                )}
                {totalImageSizeReached && (
                  <Box
                    data-testid="imagesLimit"
                    color={'red'}
                    fontWeight={'bold'}
                    sx={{ marginTop: '0.5rem' }}
                  >
                    {t('licence-plate-swap:imageSizeExceeded')}
                  </Box>
                )}
              </Box>
            </Box>
            {vehicle &&
              vehicle.licencePlate.split('-', 1)[0].length !== 3 &&
              (barcode.length === 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                    margin: '10px 10px 80px 10px'
                  }}
                >
                  <Button
                    sx={{ width: 'fit-content' }}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setIsScannerOpen(true);
                      setIsCodeTextInputOpen(false);
                      setTypedCode('');
                    }}
                  >
                    {t('licence-plate-swap:scanQr')}
                  </Button>
                  {isCodeTextInputOpen ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        size="small"
                        label={t('licence-plate-swap:typeCode')}
                        placeholder={t('licence-plate-swap:typeCode')}
                        value={typedCode}
                        onChange={(e) => {
                          setTypedCode(e.target.value);
                        }}
                        data-testid="typeCodeTextInput"
                      />
                      {typedCode.length !== 3 && <PriorityHighIcon color="error" />}
                    </Box>
                  ) : (
                    <Button
                      sx={{ width: 'fit-content' }}
                      variant="contained"
                      onClick={() => {
                        setIsCodeTextInputOpen(true);
                        setBarcode('');
                      }}
                    >
                      {t('licence-plate-swap:scanNotWorking')}
                    </Button>
                  )}
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: 2,
                    gap: 1
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography>{t('licence-plate-swap:deregistrationCode')}</Typography>
                    <Typography
                      fontSize={'large'}
                      fontWeight={'bold'}
                      sx={{
                        padding: 1,
                        backgroundColor: barcode.length !== 3 ? 'red' : 'yellow',
                        border: '1px solid black'
                      }}
                    >
                      {barcode}
                    </Typography>
                    {barcode.length !== 3 && <PriorityHighIcon color="error" />}
                  </Box>
                  <Button
                    sx={{ width: 'fit-content' }}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setIsScannerOpen(true);
                      setBarcode('');
                    }}
                  >
                    {t('licence-plate-swap:scanAgain')}
                  </Button>
                  <Button
                    sx={{ width: 'fit-content' }}
                    variant="contained"
                    onClick={() => {
                      setIsCodeTextInputOpen(true);
                      setBarcode('');
                    }}
                  >
                    {t('licence-plate-swap:scanNotWorking')}
                  </Button>
                </Box>
              ))}
          </Box>
        </>
      )}
    </>
  );
};
