import { MUTATION_KEYS } from '@/config/MutationKeys';
import { QUERY_KEYS } from '@/config/QueryKeys';
import { useUserLocation } from '@/hooks/Utility/useUserLocation';
import { MapUtils } from '@/utils/MapUtils';
import type { ExtendedBase64Image } from '@/utils/ReportUtils';
import type { Base64Image, Vehicle } from '@badgermoleV2/api';
import { getRestrictedLicencePlate, updateLicencePlate } from '@badgermoleV2/licencePlate';
import { getVehiclesSearch } from '@badgermoleV2/vehicles';
import { useMutation, useQuery } from '@tanstack/react-query';
import Compress from 'compress.js';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

// can be removed once type can be imported from api:
export interface licencePlateSwapBody {
  oldlicencePlate: String; // or better licence plate id
  imageOldLp?: Base64Image;
  imageNewLp?: Base64Image;
  vehicle?: Vehicle; // or just vin
}

export interface ReplacementData {
  vin: string;
  newLpId: number;
  deregristrationCode?: string;
  images?: Base64Image[];
}

const maximumImageSizeMb = 4;

export const uselicencePlateSwap = (
  oldlicencePlate: string,
  imageOldLp?: ExtendedBase64Image,
  imageNewLp?: ExtendedBase64Image,
  vehicle?: Vehicle
) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [processingImages, setProcessingImages] = useState(false);
  const { locationPermission, location } = useUserLocation('watch');

  const { data = [], isFetching } = useQuery({
    queryKey: [QUERY_KEYS.vehicle.byLicencePlate, oldlicencePlate],
    queryFn: async ({ signal }) =>
      await getVehiclesSearch(
        searchParams.has('vehicle') ? searchParams.get('vehicle')! : oldlicencePlate,
        { signal }
      ),
    select: useCallback(
      (data: Vehicle[]) => {
        return MapUtils.sortByDistance(data, location!);
      },
      [location]
    ),
    enabled: location !== undefined && (searchParams.has('vehicle') || oldlicencePlate.length >= 3)
  });

  const { data: replacementLpList = [], isFetching: isFetchingReplacementLpList } = useQuery({
    queryKey: [QUERY_KEYS.licencePlateSwap.getRestrictedLp, vehicle?.vin],
    queryFn: async ({ signal }) =>
      await getRestrictedLicencePlate(vehicle!.vin!, false, { signal }),
    enabled: vehicle?.vin !== undefined
  });

  const { mutateAsync: updateLp, isPending } = useMutation({
    mutationKey: [MUTATION_KEYS.licencePlateSwap.updateLp],
    mutationFn: async (replacementData: ReplacementData) => {
      await updateLicencePlate(
        replacementData.vin,
        replacementData.newLpId.toString(),
        replacementData.deregristrationCode,
        replacementData.images
      );
    },
    onSuccess: () => toast.success(t('licence-plate-swap:success'))
  });

  // This check should could be more specific or use an endpoint
  const isCompleteLpSwapValues = vehicle?.vin === undefined || replacementLpList.length > 0;

  const convertToExtendedBase64Image = (base64ImageString: string) => {
    const image = {
      imageType: base64ImageString.split('/')[1].split(';')[0],
      base64EncodedImage: base64ImageString.split('base64,')[1]
    } as Base64Image;

    const imageSizeBytes =
      base64ImageString.split('base64,')[1].replaceAll('=', '').length * (3 / 4);
    const imageSizeMB = Math.round((imageSizeBytes / 1048576) * 1e2) / 1e2;

    return {
      identifier: base64ImageString,
      image: image,
      sizeInMb: imageSizeMB
    } as ExtendedBase64Image;
  };

  const compressFileList = async (file: File) => {
    const files = [file];
    setProcessingImages(true);
    if (files) {
      const fileArray: File[] = Array.from(files!);
      const compress = new Compress();

      const compressedImages = await compress.compress(fileArray, {
        size: 4,
        quality: 0.75,
        maxWidth: 1920,
        maxHeight: 1920,
        resize: true
      });

      const filesAndCompressedImages = compressedImages.map(function (e, i) {
        return {
          compressResult: e,
          file: fileArray[i]
        };
      });

      const extendedBase64Images = await Promise.all(
        filesAndCompressedImages.map(async function (tuple) {
          const identifier = URL.createObjectURL(tuple.file);
          const imageType = tuple.compressResult.ext.split('/')[1];
          const base64Data = tuple.compressResult.data;

          const image = {
            imageType: imageType,
            base64EncodedImage: base64Data
          } as Base64Image;

          return {
            identifier: identifier,
            image: image,
            sizeInMb: tuple.compressResult.endSizeInMb
          } as ExtendedBase64Image;
        })
      );
      setProcessingImages(false);
      return extendedBase64Images;
    }
    setProcessingImages(false);
    return undefined;
  };

  const totalImageSizeReached =
    imageOldLp && imageNewLp
      ? [imageOldLp, imageNewLp].map((x) => x.sizeInMb).reduce((prev, curr) => prev + curr, 0) >
        maximumImageSizeMb
      : imageOldLp
        ? [imageOldLp].map((x) => x.sizeInMb).reduce((prev, curr) => prev + curr, 0) >
          maximumImageSizeMb
        : imageNewLp
          ? [imageNewLp].map((x) => x.sizeInMb).reduce((prev, curr) => prev + curr, 0) >
            maximumImageSizeMb
          : false;

  const disableSend =
    isPending || totalImageSizeReached || !locationPermission || !isCompleteLpSwapValues;

  return {
    vehicleListWithDistance: data,
    disableSend,
    isSending: isPending,
    updateLp,
    totalImageSizeReached,
    isLoadingVehicle: isFetching,
    compressFileList,
    processingImages,
    replacementLpList,
    isFetchingReplacementLpList,
    convertToExtendedBase64Image
  };
};
